import React, { useEffect, useState, } from "react";
import Alert from '@mui/material/Alert';
import { 
    Box, 
    Button, 
    Typography, 
    Table,
    TableContainer,
    TableHead,
    TableBody, 
    TableCell,
    TableRow,
    Menu,
    MenuItem, 
    Divider,
    Grid, 
    Drawer, 
    Dialog, 
    DialogTitle,
    DialogActions, 

} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { DocumentApi } from "../lib/apiActions";
import { useNavigate } from 'react-router-dom';
import { LoadingScreen } from '../components/LoadingScreen';
import StyledUserDocument from "../components/StyledUserDocument";
import VisuallyHiddenInput from '../components/VisuallyHiddenInput';
import moment from "moment";
import useCheckMobileScreen from "../utils/useCheckMobileScreen";
import handleFileUpload from "../components/fileUpload";

interface ComponentProps {

}

interface UploadFileProps {
    fetchDocuments: () => void;
}

const UploadFile: React.FC<UploadFileProps> = ({ fetchDocuments }) => {
    const [alert, setAlert] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const isMobile = useCheckMobileScreen();

    const InputFileUpload = () => {
        return (
            <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<FileUploadIcon />}
            disabled={isUploading}
            >
            Lataa tiedosto
            <VisuallyHiddenInput
                accept=".pdf, .docx"
                type="file"
                onChange={(event) => handleFileUpload(event, setIsUploading, fetchDocuments, setAlert)}
                multiple
            />
            </Button>
        );
    }

    return (
        <Box sx={{ // Top component
            width: { xs: undefined, sm: "100%", },
            height: { xs: undefined, sm: "15%", }, 
            order: 1,
        }}>
            <Box sx={{
                mt: { xs: undefined, sm: "15px", },
                textAlign: { xs: "center", sm: "initial", },  
            }}>
                <Box sx={{
                    height: { xs: undefined, sm: "20%", }, 
                    mb: { xs: "10px", sm: "25px", },
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row", },  
                }}>
                    <Typography sx={{
                        fontSize: "16px",
                        fontWeight: "bold", 
                        // color #2E263DE6 (add to theme)
                    }}>Omat tiedostot</Typography>
                    <Typography sx={{
                        fontSize: "14px",
                        color: "grey",
                        ml: { xs: 0, sm: "10px", },
                    }}>Voit ladata vain .pdf tai .docx tiedostoja. {isMobile ? <br /> : null} Tiedoston koko ei saa ylittää 1 MB.</Typography>
                </Box>
                <Box sx={{
                    height: { xs: undefined, sm: "80%", }, 
                    display: { xs: undefined, sm: "flex", },
                    flexDirection: { xs: undefined, sm: "row", }, 
                    alignItems: { xs: undefined, sm: "center", }, 
                }}>
                    <InputFileUpload />
                    {/* <Button 
                        variant="outlined"
                        startIcon={<CreateNewFolderIcon />}
                        sx={{
                            width: { xs: undefined, sm: "162px", }, 
                            height: { xs: undefined, sm: "43px" }, 
                            ml: { xs: undefined, sm: "25px", }, 
                        }}>
                        Luo kansio
                    </Button> */}
                    {alert && 
                        <Alert 
                            severity={alert.includes("Virhe") ? "error" : "success"} 
                            onClose={() => {setAlert(null);}}
                            sx={{
                                ml: { xs: undefined, sm: "20px", }, 
                            }}
                        >
                            {alert}
                        </Alert>
                    }
                </Box>
            </Box>
        </Box>
    );
}

interface LatestFilesProps {
    files: UserDocument[],
    handleDocumentClick: (documentId: string) => void;
}

const LatestFiles: React.FC<LatestFilesProps> = ({ files, handleDocumentClick }) => {
    return (
        <Box sx={{ // Middle component
            width: { xs: undefined, sm: "100%", },
            height: { xs: undefined, sm: "42.5%", },
            order: 2,
            display: "flex", 
            flexDirection: { xs: undefined, sm: "column", }, 
        }}>
            <Divider variant="middle" sx={{ mt: "auto", }}/>
            <Box sx={{
                width: { xs: undefined, sm: "100%", }, 
                display: { xs: "none", sm: "flex" },
                flexDirection: "column", 
                justifyContent: { xs: undefined, sm: "center" },
                alignItems: { xs: "center", sm: "initial", },  
                margin: { xs: "15px 0px", sm: "auto 0px" }, 
            }}>
                <Typography sx={{ fontWeight: "bold" }}>Viimeisimmät tiedostot</Typography>
                <Grid container spacing={2} columns={5}
                    sx={{
                        display: "flex",
                        flexDirection: { xs: undefined, sm: "row", }, 
                        width: { xs: undefined, sm: "100%", },
                        overflowX: "auto", 
                        mt: { xs: undefined, sm: "20px", },
                }}
                >
                    {files
                        .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                        .slice(0, 5)
                        .map((file) => {
                        return (
                            <Grid item key={file.id} sm={1}> 
                                <StyledUserDocument width="153px" height="163px" file={file} handleDocumentClick={() => handleDocumentClick(file.id)} ></StyledUserDocument>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
            <Divider variant="middle" sx={{ mb: "auto", }}/>
        </Box>
    )
}

export interface UserDocument {
    id: string,
    name: string,
    created: string,
}

interface AllFilesProps {
    files: UserDocument[];
    fetchDocuments: () => void;
    handleDocumentClick: any; 
}

const AllFiles: React.FC<AllFilesProps> = ({ files, fetchDocuments, handleDocumentClick }) => {
    const navigate = useNavigate();
    // useState to hold all files selected so they can all be used
    // in a single question
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, fileId: string) => {
        setAnchorEl(event.currentTarget);
        setSelectedFile(fileId);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSummarize = () => {
        navigate("/");
    };

    const handleReview = () => {
        navigate("/");
    }

    const handleConfirmDelete = (documentId: string) => {
        setSelectedFile(documentId);
        setConfirmDeleteOpen(true);
    }

    const handleDelete = async (documentId: string | null) => {
        try {
            const response = await DocumentApi.delete(documentId || "", null);
            if (response.status >= 300) {
                return;
            }
            console.log("document deleted successfully");
            fetchDocuments();
        } catch(error) {
            console.log("Error: " + error);
        }
    }

    const TableRows = files.map((file, index) => {
        return (
            <>
                <TableRow key={index}>
                    <TableCell sx={{
                        maxWidth: { xs: "25px", sm: "80px", }, 
                        whiteSpace: 'nowrap', 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis' 
                    }}>{file.name}</TableCell>
                    <TableCell>{moment(file.created).format("D.M.YYYY H.mm")}</TableCell>
                    <TableCell sx={{
                        display: "flex", 
                        justifyContent: "flex-end",
                    }}>
                        <IconButton aria-label="delete" onClick={() => handleConfirmDelete(file.id)}>
                            <DeleteIcon />
                        </IconButton>

                        <IconButton aria-label="more" onClick={(event) => {handleClick(event, file.id)}}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu 
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleSummarize}>Yhteenveto</MenuItem>
                            <MenuItem onClick={handleReview}>Tarkistus</MenuItem>
                            <MenuItem onClick={() => {handleDocumentClick(selectedFile); handleClose();}}>Esikatselu</MenuItem>
                        </Menu>
                    </TableCell>
                </TableRow>
            </>
        );
    })
    return (
        <>
            <Box sx={{ // Bottom component
                width: { xs: undefined, sm: "100%", },
                height: { xs: undefined, sm: "42.5%", }, 
                order: 3,
                mt: "auto", 
            }}>
                <TableContainer sx={{
                    width: "100%", 
                    height: "100%",
                    overflowY: "auto",
                }}>
                    <Table stickyHeader >
                        <TableHead> {/* backgroundColor tähän */}
                            <TableCell sx={{ backgroundColor: "#F6F7FB" }}>Tiedoston nimi</TableCell>
                            <TableCell sx={{ backgroundColor: "#F6F7FB" }}>Ladattu</TableCell>        {/* fix background color to one and take from theme */}
                            <TableCell align="right" sx={{ backgroundColor: "#F6F7FB" }}>Toiminnot</TableCell>
                        </TableHead>
                        <TableBody>
                            {TableRows}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog 
                    open={confirmDeleteOpen}
                    onClose={() => setConfirmDeleteOpen(false)}
                >
                    <DialogTitle>Haluatko varmasti poistaa tiedoston?</DialogTitle>
                    <DialogActions>
                        <Button
                            onClick={() => {handleDelete(selectedFile); setConfirmDeleteOpen(false);}} 
                            variant="outlined" 
                            sx={{ 
                                color: "red", 
                                borderColor: "red", 
                            }}>Poista</Button>
                        <Button onClick={() => setConfirmDeleteOpen(false)}>Peruuta</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
}

interface FilePreviewProps {
    id: string | "",
    name: string | "",
    created: string | "",
    setSelectedFile: any,
}

const FilePreview: React.FC<FilePreviewProps> = ({ id, name, created, setSelectedFile }) => {
    const file: UserDocument = {
        "id": id,
        "name": name,
        "created": created, 
    }
    const navigate = useNavigate();
    const open = id !== "";
    const [contentPreview, setContentPreview] = useState<string | null>(null);
    const fetchDocumentContentPreview = async () => {
        try {
            const params = { start_only: "true" };
            const response = await DocumentApi.get(params, null, id);
            if (response.status >= 300) {
                return;
            }
            setContentPreview(response.data);
        } catch (error) {
            console.log("Error: " + error);
        }
    }

    useEffect(() => {
        fetchDocumentContentPreview();
    }, [id]);

    return (
        <Drawer 
        open={open}
        anchor="right"
        variant="temporary"
        // ModalProps={{ keepMounted: true, }}
        elevation={16}
        role="presentation"
        onClose={() => setSelectedFile(null)}
        sx={{
            '& .MuiDrawer-paper': {
                width: { xs: "100%", sm: "25%", }, 
                height: { xs: "100%", sm: "100%", }, 
                position: "fixed", 
                right: 0, 
                top: 0,
                backgroundColor: "#FFFFFF", // move to theme
            }
        }}>
            <Box sx={{ // Container
                width: "90%", 
                height: "90%", 
                margin: "auto",  
                display: "flex",
                flexDirection: "column", 
            }}>
                <Box sx={{ // Title & close button
                    width: { xs: undefined, sm: "90%", },
                    height: { xs: undefined, sm: "48px", },
                    display: "flex",
                    flexDirection: "row", 
                    justifyContent: "space-between",
                    alignItems: "center", 
                    margin: { xs: undefined, sm: "0 auto 10px auto", }, 
                }}>
                    <Typography sx={{ 
                        fontWeight: "bold", 
                        fontSize: { xs: undefined, sm: "16px", }, 
                    }}>Tiedoston esikatselu</Typography>
                    <IconButton aria-label="close" onClick={() => setSelectedFile(null)} sx={{ justifyContent: "end" }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ // File preview container
                    width: { xs: undefined, sm: "100%", },
                    height: { xs: "intial", sm: "490px", },
                }}>
                    <Box sx={{ // File contents
                        width: { xs: undefined, sm: "90%", },
                        height: { xs: undefined, sm: "75%", },
                        margin: "0 auto", 
                        border: "1px solid #E6E6E9", // border color from theme
                        borderRadius: "8px", // theme
                    }}>
                        <Typography sx={{ 
                            height: "10%", 
                            width: "90%", 
                            margin: "10px auto",
                            display: "flex",
                            alignItems: "center", 
                            fontWeight: "bold", 
                            fontSize: { xs: undefined, sm: "15px", },  
                            whiteSpace: 'nowrap', 
                            overflow: 'hidden', 
                            textOverflow: 'ellipsis', 
                        }}>{name}</Typography>
                        <Typography sx={{ 
                            height: "90%",
                            width: "90%",
                            margin: "0 auto",
                            overflow: "auto",  
                        }}>{contentPreview}</Typography>
                    </Box>
                    <Box sx={{ // File icon & name
                        width: { xs: "100%", sm: "90%", }, 
                        height: "20%", 
                        display: "flex", 
                        flexDirection: "row", 
                        margin: { xs: "2vh 0px", sm: "0 auto", }, 
                        justifyContent: { xs: undefined, sm: "flex-start", }, 
                        alignItems: "center",  
                    }}>
                        <StyledUserDocument file={file} useName={false} width="72px" height="72px" ></StyledUserDocument>
                        <Typography sx={{ 
                            textAlign: { xs: "center", sm: undefined, }, 
                            ml: { xs: "20px", sm: "20px", }, 
                            whiteSpace: 'nowrap', 
                            overflow: 'hidden', 
                            textOverflow: 'ellipsis' 
                        }}>{name}</Typography>
                    </Box>
                </Box>
                <Box sx={{ // File properties
                    width: "100%", 
                    height: { xs: undefined, sm: "25%", }, 
                    mb: { xs: "20px", sm: "0px", }, 
                }}>
                    <Divider />
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column", 
                        margin: { xs: undefined, sm: "20px 0", }, 
                        gap: { xs: "5px", sm: "10px", }, 
                    }}>
                        <Typography sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            mb: { xs: undefined, sm: "10px", }, 
                        }}>Ominaisuudet</Typography>
                        <Box sx={{
                            display: "flex",
                            flexDirection: { xs: undefined, sm: "row", }, 
                            justifyContent: "space-between", 
                            fontSize: "15px", 
                        }}>
                            <Typography>Latauspäivä</Typography>
                            <Typography>{moment(created).format("D.M.YYYY H.mm")}</Typography>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: { xs: undefined, sm: "row", }, 
                            justifyContent: "space-between", 
                        }}>
                            <Typography>Tyyppi</Typography>
                            <Typography>{name.includes(".") ? name.slice(name.lastIndexOf(".") + 1) : "-"}</Typography>
                        </Box>
                    </Box>
                    <Divider />
                </Box>
                <Box sx={{ // File actions
                    width: { xs: undefined, sm: "100%", }, 
                    height: { xs: undefined, sm: "10%", },
                    display: "flex",
                    flexDirection: { xs: undefined, sm: "row", },
                    justifyContent: { xs: "space-between", sm: "space-evenly", },   
                }}>
                    <Button
                        onClick={() => navigate("/")} 
                        variant="contained" 
                        startIcon={<FindInPageIcon />} 
                        sx={{
                            height: { xs: undefined, sm: "50%", }, 
                        }}
                        >Tarkistus</Button>
                    <Button
                        onClick={() => navigate("/")}  
                        variant="contained" 
                        startIcon={<DocumentScannerIcon />} 
                        sx={{
                        height: { xs: undefined, sm: "50%", }, 
                        }}
                        >Yhteenveto</Button>
                </Box>
            </Box>

        </Drawer>
    );
}

const FileManagementView: React.FC<ComponentProps> = () => {

    const [loading, setLoading] = useState(true);
    const [files, setFiles] = useState<UserDocument[]>([]);
    const [selectedFile, setSelectedFile] = useState<UserDocument | null>(null);
    const handleDocumentClick = (id: string) => {
        setSelectedFile(files.find(file => file.id === id) || null);
    }
    const fetchDocuments = async () => {
        try {
            setLoading(true);
            const response = await DocumentApi.getAll(null);
            if (response.status >= 300) {
                return;
            }
            console.log("documents api response: " + response.data);
            setFiles(response.data);
        } catch (error) {
            console.log("Error: " + error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchDocuments();
    }, []); 

    return (
        <>
            {loading ? <LoadingScreen /> : 
            (<Box sx={{
                width: { xs: undefined, sm: "70vw", }, 
                height: { xs: undefined, sm: "85vh", }, 
                margin: { xs: undefined, sm: "auto", }, 
                border: "1px solid #E6E6E9",
                borderRadius: { xs: undefined, sm: "8px", },
                backgroundColor: "#FCFCFC", // move to theme
                padding: { xs: undefined, sm: "0px 50px"},
                mt: { xs: "15px", sm: undefined }, 
            }}>
                <UploadFile fetchDocuments={fetchDocuments}/>
                <LatestFiles files={files} handleDocumentClick={handleDocumentClick} />
                <AllFiles files={files} handleDocumentClick={handleDocumentClick} fetchDocuments={fetchDocuments}/>
                {selectedFile && (
                    <FilePreview setSelectedFile={setSelectedFile} id={selectedFile.id} name={selectedFile.name} created={selectedFile.created}/>
                )}
            </Box>)
            }
        </>
    );
}

export default FileManagementView;