import React, { useState, useEffect, ReactNode } from 'react';

import {
    Container,
    Grid,
    Typography,
    Box,
    ListItem,
    ListItemIcon,
    ListItemText,
    Link,
    Button,
    Divider,
    IconButton,
    useTheme,
    Menu,
    MenuItem,
    ListItemButton,
    Stack
} from '@mui/material';
import { Message, Source, chatSelector, setAlert } from '../../lib/ducks/chatDuck';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { ThumbDown, ThumbUp, Info } from '@mui/icons-material';
import { MessageDialog } from '../dialogs/messageDialog';
import { InputDialog } from '../dialogs/dialogs';
//import { ChatApi } from '../../lib/apiActions';
import { ChatApi } from '../../lib/apiActions';
import { useAppDispatch, useAppSelector } from '../../lib/hook';
import LoopIcon from '@mui/icons-material/Loop';
import CheckIcon from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WarningIcon from '@mui/icons-material/Warning';
import { FileIcon } from '../StyledUserDocument';
import StopIcon from '@mui/icons-material/Stop';
import { authSelector } from '../../lib/ducks/authDuck';
import StyledAvatar from '../avatar';
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown';
import Skeleton from '@mui/material/Skeleton';
import { getPipeColor } from '../../utils/pipeColor';
import useCheckMobileScreen from '../../utils/useCheckMobileScreen';
import DescriptionIcon from '@mui/icons-material/Description';
import { ContentDialog } from '../dialogs/contentDialog';

export const SentMessage = (props: any) => {
    const theme = useTheme()
    const user = useAppSelector(authSelector).user;
    return (
        <Box
            sx={{
                backgroundColor: "transparent",
                padding: 0,
                margin: 0,
                width: { xs: "95%", sm: undefined }, 
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box sx={{ position: 'relative', }}>
                <StyledAvatar avatarName={user?.avatar_name} size={42} sx={{ 
                    position: 'absolute', 
                    marginLeft: { xs: "104%", sm: "calc(100% + 8px)" },
                    marginTop: { xs: "8px", sm: '-16px', },  
                }}/>
            </Box>
            <Box sx={{
                backgroundColor: theme.palette.bubbleColor,
                padding: { xs: "18px 26px", sm: "32px 48px 32px 48px" },
                borderRadius: { xs: "24px", sm: "48px 0px 48px 48px" },
                marginTop: 1,
                marginRight: 0,
                maxWidth: '816px',
                width: { xs: "95%", sm: '90%' },
                alignSelf: 'flex-end', 
            }}
            >
                <Typography sx={{ fontSize: 16, }} style={{ whiteSpace: 'pre-line' }}>{props.text}</Typography>

            </Box>

        </Box>
    )
}


interface ComponentProps {
    message: Message;
    sendMessage: any;
    handleAbort: any;
    rephraseMessage: any;
    lastMessage: boolean;
    handleSummarize: any;
}


//Recevied Message
export const ReceivedMessage: React.FC<ComponentProps> = ({ message, sendMessage, handleAbort, rephraseMessage, lastMessage, handleSummarize }) => {
    const isMobile = useCheckMobileScreen();
    const [open, setOpen] = React.useState(false);
    const [feedback, setFeedback] = React.useState<boolean | null>(null);
    const dispacth = useAppDispatch()
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedSources, setSelectedSources] = useState<string[]>([]);
    const [showResultCount, setShowResultsCount] = useState(3);
    const isList = message.response_type === "list";
    const roles = useAppSelector(authSelector).user?.roles
    const selectedPipe = useAppSelector(chatSelector).selectedPipe
    const messagePipe = (roles && message.role_id)
        ? roles.find(role => role.uuid === message.role_id)?.name || selectedPipe || "default"
        : selectedPipe || "default";
    //const messagePipe = (roles && message.role_id) ? (Object.keys(roles) as Array<keyof typeof roles>).find((key) => roles[key] === message.role_id) : selectedPipe ? selectedPipe : "default"
    //const messagePipe = (roles) ? (Object.keys(roles) as Array<keyof typeof roles>).find((key) => roles[key] === "ac771f5c-cf8d-4057-a9f6-2eeb69d5f480") : "default"

    const darkTheme = useAppSelector(authSelector).darkTheme
    const menuOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };



    const handleSelectedSources = (source: string | null) => {
        if (source === null) {
            setSelectedSources([]);
            return;
        }
        if (selectedSources.includes(source)) {
            setSelectedSources(l => l.filter(item => item !== source))
        } else {
            setSelectedSources([...selectedSources, source]);
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleResend = () => {
        sendMessage(message.orgMessage, selectedSources);
    };

    const handleSend = (message: string) => {
        sendMessage(message, []);
    };

    const handleFeedback = async (text: string) => {
        //receiving message always has id
        if (message.id) {
            const result = await ChatApi.feedback(message.id, { grade: feedback, text: text }, { validateStatus: false });
            if (result.status < 300) {
                dispacth(setAlert({ message: 'Palaute lähetetty', type: 'success', key: (new Date()).toString() }))
            } else {
                dispacth(setAlert({ message: 'Palautteen lähetys epäonnistui', type: 'error', key: (new Date()).toString() }))
            }
        }

        setFeedback(null);
    }

    const formatDocumentsInResponse = (message: string) => {
        const docPattern = /\[!doc:\s*([^\]]+)\]:\s*([^[]*)/g; 
        const matches = Array.from(message.matchAll(docPattern));
        return (
            <Box>
                {matches.map((match: Array<string>, index: number) => (
                    <>
                        <FileIcon name={match[1]} width={"15px"} height={"15px"} useName={true}/>
                        <Typography sx={{
                            mb: {xs: "0px", sm: "15px", }, 
                        }}>{match[2]}</Typography>
                    </>
                ))}
            </Box>
        )
    }

    const summaryOptions = [
        { label: "Lyhyt", value: "brief" },
        { label: "Pitkä", value: "long" }, 
        { label: "Kattava", value: "comprehensive" }, 
    ]


    return (
        <>
            <InputDialog
                title='Lähetä palaute'
                text='Palautteesi on tärkeää meille!'
                required={false}
                okText='Lähetä'
                cancelText='Peruuta'
                multiline={true}
                open={feedback != null}
                handleClose={() => setFeedback(null)}
                submit={handleFeedback}
            />
            <Box
                sx={{
                    backgroundColor: "transparent",
                    padding: 0,
                    margin: 0,
                    marginBottom: 5,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >

                <Box sx={{
                    backgroundColor: { xs: theme.palette.responseBubbleColorMobile, sm: theme.palette.responseBubbleColor },
                    padding: { xs: "18px 26px", sm: "32px 48px 32px 48px" },
                    borderRadius: { xs: "24px 24px 24px 0px", sm: "48px 48px 48px 0px" },
                    //borderRadius: 5,
                    marginTop: 1,
                    marginLeft: 0,
                    maxWidth: '816px',
                    width: { xs: "75vw", sm: "90%" },
                    alignSelf: 'flex-start'
                    //maxWidth: 500,
                    //padding: 14,

                    //alignItems:"center",

                }}
                    onClick={() => { }}

                >

                <Box sx={{ position: 'relative', }}>
                    <StyledAvatar avatarName={message.isLoading ? 'chatbot-typing.gif' : 'chatrobot.png'} size={42} sx={{
                        position: 'absolute',
                        marginLeft: { xs: "-25%", sm: "-100px" },
                        marginTop: { xs: "5%", sm: undefined },
                        bgcolor: getPipeColor(messagePipe)
                    }} />
                </Box>
                    {message.sources.length === 0 && !message.isLoading && message.response_type != "rephrase" && message.warning &&
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                        >
                            <WarningIcon color='warning' sx={{ marginRight: 2 }} />
                            <Typography
                                fontSize={'15px'}
                                fontWeight={400}
                                fontStyle={'italic'}
                            >
                                Huomio! Vastaukselle ei löytynyt lähteitä Codexin omasta tietokannasta, vaan perustuu internetistä löytyvään tietoon, eikä vastaus välttämättä ole luotettava.
                            </Typography>
                        </Box>
                    }
                    {(!message.isLoading && message.response_type != "rephrase") && message.response_type != "summary" && 
                        <>
                            <ContentDialog message={message} handleDocumentSourceClick={() => handleClickOpen()}></ContentDialog>
                        </>
                    }

                    {message.isLoading &&
                        <Stack spacing={1}>
                            {/* For variant="text", adjust the height via font-size */}
                            <Skeleton variant="text" width={210} sx={{ fontSize: '2rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </Stack>
                    }

                    {(!message.isLoading && message.response_type === "rephrase") &&
                        <>
                            <Typography>
                                Haluatko muotoilla kysymyksen uudelleen, mahdollisesti parantaen vastausta:
                            </Typography>
                            <Typography
                                fontWeight={'bold'}
                            >
                                '{message.content}'
                            </Typography>
                            <Box marginTop={2}
                            sx={{
                                display: 'flex',
                                justifyContent: { xs: "center", sm: "initial" }, 
                                flexDirection: { xs: "column", sm: "row", }, 
                            }}>
                                <Button variant='contained'
                                    onClick={() => handleSend(message.content.toString())}
                                    disabled={!lastMessage}
                                >
                                    Käytä ehdotusta
                                </Button>
                                <Button variant='outlined' sx={{ marginLeft: { xs: 0, sm: 2 }, }}
                                    onClick={() => handleSend(message.orgMessage)}
                                    disabled={!lastMessage}
                                >
                                    Pidä alkuperäinen kysymys
                                </Button>
                            </Box>
                        </>
                    }

                    {message.sources.length === 0 && !message.isLoading && message.response_type != "rephrase" && message.warning &&
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'flex-start'}
                        >
                            <Typography
                                fontSize={'15px'}
                                fontWeight={400}
                                fontStyle={'italic'}
                            >
                                Haluatko apua kysymyksen uudelleen muotoiluun, mikä voi parantaa vastausta ja löytää sopivampia lähteitä?
                            </Typography>
                            <Button variant='contained' sx={{ marginTop: 2 }}
                                onClick={() => rephraseMessage(message.orgMessage, true)}
                                disabled={!lastMessage}
                            >
                                {isMobile ? "Muotoile uudelleen" : "Kyllä, auta muotoilemaan uudelleen"}
                            </Button>
                        </Box>
                    }

                    {!message.isLoading && message.response_type === "mock_summary" && 
                        <Box sx={{
                            display: "flex", 
                            gap: { xs: undefined, sm: "12px"}, 
                        }}>
                            {summaryOptions.map(({ label, value }) => (
                                <Button key={value} variant="outlined" color='primary' onClick={() => handleSummarize(value)} disabled={!lastMessage}>
                                    {label}
                                </Button>
                            ))}
                        </Box>
                    }

                    {!message.isLoading && message.response_type == "summary" &&
                        <Box>
                            {formatDocumentsInResponse(message.content)}
                        </Box>
                    }


                    {message.sources.length > 0 && !message.isLoading &&
                        <>
                            <Divider sx={{ m: 1, marginBottom: 2 }} />
                            <Typography
                                fontSize={'16px'}
                                fontWeight={700}
                                sx={{ marginBottom: 1 }}
                            >
                                Lähteet
                            </Typography>

                        </>

                    }

                    {!message.isLoading &&
                        message.sources?.slice(0, isList ? showResultCount : message.sources.length).map((source: Source, index: number) => (
                            <Box key={index + "div"}>

                                {source.href && 
                                <Link target="_blank" rel="noopener"
                                    href={source.href}
                                    key={index + "srclink"}
                                    sx={{
                                        borderRadius: 8,
                                        backgroundColor: '#5870F026',
                                        padding: '6px 16px 6px 16px',
                                        fontSize: 14,
                                        justifyContent: "center",
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden !important',
                                        whiteSpace: 'nowrap',
                                        display: 'inline-block',
                                    }}
                                    style={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}>
                                    {source.source} {source.title ?? source.href} <br />
                                </Link >
                                }
                                {source.mvs &&
                                    <Typography key={index + "mvstypo"} sx={{ paddingLeft: 3 }}>
                                        <Link target="_blank" rel="noopener"
                                            href={source.mvs.link}
                                            key={index + "mvs"}
                                            sx={{
                                                borderRadius: 8,
                                                backgroundColor: '#5870F026',
                                                padding: '6px 16px 6px 16px',
                                                fontSize: 14,
                                                justifyContent: "center",
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden !important',
                                                whiteSpace: 'nowrap',
                                                display: 'inline-block',
                                            }}
                                            style={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}>
                                            {source.mvs.title} <br />
                                        </Link >
                                    </Typography>
                                }
                                {source.href == "" && message.response_type === "review" && 
                                    <Button startIcon={<DescriptionIcon/>} onClick={() => handleClickOpen()} sx={{
                                        borderRadius: 8,
                                        backgroundColor: '#5870F026',
                                        padding: '6px 16px 6px 16px',
                                        fontSize: 14,
                                        justifyContent: "center",
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden !important',
                                        whiteSpace: 'nowrap',
                                        display: 'flex',
                                        alignItems: "center", 
                                        color: "black", 
                                    }}>
                                        {source.title}
                                    </Button>
                                }
                            </Box>
                        ))
                    }
                    {(isList && showResultCount < message.sources.length) &&
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                endIcon={<KeyboardArrowDownIcon />}
                                onClick={() => setShowResultsCount(showResultCount + 3)}
                            >
                                Näytä lisää
                            </Button>
                        </Box>
                    }
                    {message.isLoading && message.response_type === "default" &&
                        <Box
                            sx={{
                                position: 'relative',
                                maxWidth: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        ><Box
                            sx={{
                                position: "absolute",
                                borderRadius: '8px',
                                top: 0,
                                //left: 'calc(50% - 72px)',
                                backgroundColor: 'white',
                                backdropFilter: 'blur(40px)',
                                marginTop: 2
                            }}
                        >
                                <Button
                                    onClick={handleAbort}
                                    startIcon={<StopIcon />}
                                    sx={{
                                        ...{ color: darkTheme ? 'white' : '#2C2F33' },
                                        textTransform: "none",
                                        fontWeight: "600",
                                        letterSpacing: "0.01em"
                                    }}
                                >
                                    Peruuta kysely
                                </Button>
                            </Box>
                        </Box>
                    }


                    {message.id &&
                        <Box
                            sx={{
                                position: 'relative',
                                maxWidth: '80%',
                                //borderRadius: 5,
                                marginLeft: "5%",
                                //backgroundColor: theme.palette.boxShade,
                                //backdropFilter: 'blur(40px)'
                            }}
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    borderRadius: 5,
                                    top: 0,
                                    right: -80,
                                    p: 1,
                                    marginLeft: "5%",
                                    backgroundColor: theme.palette.boxShade,
                                    backdropFilter: 'blur(40px)'
                                }}
                            >
                                <IconButton
                                    onClick={() => setFeedback(true)}
                                    size="small"
                                >
                                    <ThumbUp fontSize="inherit" />
                                </IconButton>
                                <IconButton
                                    onClick={() => setFeedback(false)}
                                    size="small"
                                >
                                    <ThumbDown fontSize="inherit" />
                                </IconButton>

                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                >
                                    <LoopIcon fontSize="inherit" />
                                </IconButton>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <ListItem >
                                        <ListItemText>Valitse lähteet mitä et halua käyttää uudessa vastauksessa</ListItemText>
                                    </ListItem >
                                    <Divider />
                                    {message.sources.filter((value: Source, index, array) =>
                                        array.findIndex((v: Source) => v.id === value.id) === index
                                    ).map((source: Source, index: number) => (
                                        <MenuItem key={"menu" + index} onClick={() => handleSelectedSources(source.id)}>
                                            {selectedSources.includes(source.id) &&
                                                <ListItemIcon>
                                                    <CheckIcon fontSize="small" />
                                                </ListItemIcon>
                                            }
                                            <ListItemText>{source.href}</ListItemText>
                                        </MenuItem>
                                    ))}
                                    <Divider />
                                    <ListItem >

                                        <ListItemButton

                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                            onClick={() => { handleMenuClose(); handleResend(); }}
                                        >
                                            <ListItemIcon
                                            >
                                                <SendIcon />
                                            </ListItemIcon>
                                            <ListItemText>Lähetä</ListItemText>
                                        </ListItemButton>
                                    </ListItem >
                                </Menu>

                                <IconButton
                                    onClick={handleClickOpen}
                                    size="small"
                                >
                                    <Info fontSize="inherit" />
                                </IconButton>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
            <MessageDialog key={message.id} open={open} handleClose={handleClose} message={message} />
        </>

    )
}