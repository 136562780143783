import { DocumentApi } from "../lib/apiActions";

const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>,  setIsUploading: any, fetchDocuments: any, setAlert?: any) => {
        setIsUploading(true);
        const file = event.target.files?.[0];
        if (!file) return;
        if (file.size > 1048576) {
            if (setAlert) setAlert("Virhe: Tiedoston koko ei saa ylittää 1 MB.");
            setIsUploading(false);
            return;
        }
        try {
            const formData = new FormData();
            formData.append("file", file);
            await DocumentApi.create(formData, { headers: { "Content-Type": "multipart/form-data" } });
            console.log("document uploaded successfully");
            if (setAlert) setAlert("Tiedosto ladattu onnistuneesti");
            fetchDocuments();
        } catch (error) {
            if (setAlert) setAlert("Virhe tiedoston latauksessa");
            console.log("Error: " + error);
        } finally {
            setIsUploading(false);
        }
    }

export default handleFileUpload