import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
//import { ChatApi } from "../apiActions";
import { RootState } from "../store";
import { Message } from "@mui/icons-material";
import { ChatApi, DocumentApi } from "../apiActions";
import { AlertModel } from "./alertType";

export interface Chat {
    id: string,
    title: string,
    updated: string
}
export interface Message {
    id: string | null,
    content: string, 
    role: number,
    timestamp: string | null,
    sources: Source[],
    orgMessage: string,
    response_type: string,
    isLoading: boolean,
    warning?: boolean,
    role_id?: string,
}

export interface RephraseMessage {
    message: string,
    save_message?: boolean,
    sources_tables: string[],
    role_id?: string | undefined
}

export interface ServerMessage {
    id: string,
    message: string,
    response: string,
    timestamp: string,
    sources: Source[],
    response_type: string,
    warning: boolean
    rephrase_used: boolean
    role_id?: string,
}

export interface Source {
    id: string,
    source: number,
    href: string,
    citation: string,
    title: string | null,
    mvs: Mvs | null
}

interface Mvs {
    title: string,
    link: string
}

interface ChatState {
    conversations: Chat[],
    selectedChat: Message[],
    selectedChatId: string | null,
    isLoading: boolean,
    messageIsLoading: boolean,
    alert: AlertModel | null,
    query_quota: number,
    firstLoading: boolean,
    selectedPipe: string
}

// is this and review necessary?
interface summarizeDocument {
    type: string,
    file_id: string[],
    message: string;
}

interface reviewDocument {
    message: string,
    file_id: string[],
}

export interface NewMessage {
    message: string,
    exclude_ids: string[],
    sources_tables: string[],
    role_id?: string | undefined
}

//export const SourcesTables = ["ajantasainenlaki", "vero_ohjeet", "korkeinoikeus", "korkeinhallintooikeus", "hovioikeus"];
export const SourcesTables = [
    { "names": ["ajantasainenlaki"], "displayName": "Lainsäädäntö" },
    { "names": ["vero_ohjeet"], "displayName": "Vero ohjeet" },
    { "names": ["korkeinoikeus"], "displayName": "Korkein oikeus" },
    { "names": ["korkeinhallintooikeus"], "displayName": "Korkein hallinto-oikeus" },
    { "names": ["vakuutusoikeus"], "displayName": "Vakuutusoikeus" },
    { "names": ["hallintooikeudet"], "displayName": "Hallinto-oikeudet" },
    { "names": ["markkinaoikeus"], "displayName": "Markkinaoikeus" },
    { "names": ["tyotuomioistuin"], "displayName": "Työtuomioistuin" },
    { "names": ["hovioikeus"], "displayName": "Hovioikeus" },
    { "names": ["euroopanihmisoikeustuomioistuin", "eutuomioistuin", "eutuomioistuinluganoratkaisut"], "displayName": "EU-tuomioistuin", },
    { "names": ["eurlex"], "displayName": "Eurlex" },
    { "names": ["tyoehtosopimukset"], "displayName": "Työehtosopimukset" },
    { "names": ["dvv"], "displayName": "Digi- ja väestötietovirasto" },
    { "names": ["mml"], "displayName": "Maanmittauslaitos" },
    { "names": ["valtioneuvosto"], "displayName": "Valtioneuvosto" },
    { "names": ["valtiosopimukset"], "displayName": "Valtiosopimukset" },
];

export const chatInitialState = {
    conversations: [],
    selectedChat: [],
    isLoading: false,
    messageIsLoading: false,
    selectedChatId: null,
    alert: null,
    query_quota: 0,
    firstLoading: true,
    selectedPipe: "default"
} as ChatState


//Thunk functions
export const fetchChats = createAsyncThunk("chat/fetchChats", async () => {
    const response = await ChatApi.getAll(null);
    return response.data;
})

export const fetchChat = createAsyncThunk("chat/fetchChat", async (id: String) => {
    const response = await ChatApi.get(id, null);
    return response.data;
})

const deleteChat = createAsyncThunk("chat/deleteChat", async (id: String) => {
    const response = await ChatApi.delete(id, null);
    return response.data;
})
const abortChat = createAsyncThunk("chat/abortChat", async (id: String) => {
    const response = await ChatApi.abort(id, null);
    return response.data;
})

const postChat = createAsyncThunk("chat/postChat", async ({ id, body }: { id: String, body: NewMessage }) => {
    const response = await ChatApi.post(id, body, null);
    return response.data;
})

const summarizeDocuments = createAsyncThunk("chat/summarizeDocuments", async ({ id, body, }: { id: string, body: summarizeDocument }) => {
    const response = await DocumentApi.summarize(body, null, id);
    return response.data;
})

const reviewDocuments = createAsyncThunk("chat/reviewDocuments", async ({ id, body }: { id: string, body: reviewDocument }) => {
    const response = await DocumentApi.review(body, null, id);
    return response.data;
})

const delay = (delayInms: number) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
};

const mockChat = createAsyncThunk("chat/postChat", async ({ body }: { body: any }) => {
    await delay(500);
    const response = await ChatApi.mock(body, null);
    return response.data;
})

const createChat = createAsyncThunk("chat/createChat", async ({ body }: { body: NewMessage }) => {
    const response = await ChatApi.create(body, null);
    return response.data;
})

export const rephraseChat = createAsyncThunk("chat/rephrase", async ({ id, body }: { id: string, body: RephraseMessage }) => {
    const response = await ChatApi.rephraseWithSave(id, body, null);
    console.log("rephraseChat response", response);
    return { data: response.data, status: response.status };
})

export const deleteAndFetchChats = (id: string) => async (dispatch: any) => {
    await dispatch(deleteChat(id))
    dispatch(fetchChats())
    // do more here, dispatch something else
}

export const rephraseAndFetchChats = (id: string, body: RephraseMessage) => async (dispatch: any) => {
    const response = await dispatch(rephraseChat({ id, body }))
    console.log("dispatch rephraseChat response", response);
    const conversation_id = id != "" ? id : response.payload.data.conversation_id;
    if (!response.error) {
        if (response.payload.status == 201) {
            console.log("send message without rephrase");
            await dispatch(fetchChat(conversation_id));
            await dispatch(postAndFetchChats(conversation_id, { message: body.message, exclude_ids: [], sources_tables: body.sources_tables, role_id: body.role_id }))
            return;
        }
    }
    dispatch(fetchChat(conversation_id));
    dispatch(fetchChats())
}

export const mockAndFetchChats = ( message: string ) => async (dispatch: any) => {
    const body = { message: message }
    const response = await dispatch(mockChat({ body }))
    console.log("dispatch mockChat response", response);
    if (response.error) {
        console.error("mockchat error", response)
        return;
    }
    const conversation_id = response.payload.conversation_id;
    console.log("mock conversation id", conversation_id)
    dispatch(fetchChat(conversation_id));
    dispatch(fetchChats())
}

export const postAndFetchChats = (id: string, body: NewMessage) => async (dispatch: any) => {
    const response = await dispatch(postChat({ id, body }))
    console.log("postAndFetchChats response", response);
    if (!response.error) {
        dispatch(fetchChat(id));
        dispatch(fetchChats())
    }
}

export const summarizeAndFetchChats = (id: string, documentIds: string[], type: string, message: string) => async (dispatch: any) => {
    const response = await dispatch(summarizeDocuments({ id, body: {
        type: type, file_id: documentIds,
        message: message
    } }))
    console.log("summarizeAndFetchChats response", response);
    const conversation_id = id != "" ? id : response.payload.conversation_id;
    if (!response.error) {
        dispatch(fetchChat(conversation_id));
        dispatch(fetchChats())
    }
}

export const reviewAndFetchChats = (id: string, documentIds: string[], message: string) => async (dispatch: any) => {
    const response = await dispatch(reviewDocuments({ id, body: { message: message, file_id: documentIds } }))
    console.log("reviewAndFetchChats response", response);
    const conversation_id = id != "" ? id : response.payload.conversation_id;
    if (!response.error) {
        dispatch(fetchChat(conversation_id));
        dispatch(fetchChats())
    }
}

export const abortAndFetchChat = (id: string) => async (dispatch: any) => {
    await dispatch(abortChat(id))
    //might not be necessary to refetch as the original request will fetch messages again
    //dispatch(fetchChat(id));
}

export const createAndFetchChats = (body: NewMessage) => async (dispatch: any) => {
    const response = await dispatch(createChat({ body }));
    console.log("create chat dispacth response", response);
    if (!response.error) {
        dispatch(fetchChat(response.payload.conversation_id));
        dispatch(fetchChats());
    }

}

export const renameAndFetch = (id: string, body: any) => async (dispatch: any) => {
    await ChatApi.rename(id, body, null);
    dispatch(fetchChats());
}



const chatSlice = createSlice({
    name: 'chat',
    initialState: chatInitialState,
    reducers: {
        setSelectedChatId(state, action: PayloadAction<string | null>) {
            state.selectedChatId = action.payload
            state.selectedChat = [];
        },
        setAlert(state, action: PayloadAction<AlertModel>) {
            state.alert = action.payload
        },
        resetAlert: (state) => { state.alert = null },
        setSelectedPipe(state, action: PayloadAction<string>) {
            state.selectedPipe = action.payload
        }
    },

    extraReducers: (builder) => {
        builder
            //postChat
            .addCase(postChat.pending, (state, action) => {
                const messages = state.selectedChat;
                messages.push({
                    id: null,
                    content: action.meta.arg.body.message,
                    role: 1,
                    timestamp: null,
                    sources: [],
                    orgMessage: "",
                    response_type: "default",
                    isLoading: false
                })
                messages.push({
                    id: null,
                    content: "Vastausta ladataan...",
                    role: 2,
                    timestamp: null,
                    sources: [],
                    orgMessage: "",
                    response_type: "default",
                    isLoading: true
                })
                state.selectedChat = messages;
                state.messageIsLoading = true;
            })
            .addCase(postChat.fulfilled, (state, action) => {
                //state.selectedChat = action.payload.conversations;
                console.log("createChat.fulfilled", action)
                if (action.payload.conversation_id) {
                    state.selectedChatId = action.payload.conversation_id;
                }
            })
            .addCase(postChat.rejected, (state, action) => {
                console.log("error while postChat:", action)
                if (action.error.message === "Request failed with status code 418") {
                    state.alert = { message: 'Virhe tapahtui. Tarkista tokenien määrä.', type: 'error', key: (new Date()).toString() }
                } else {
                    state.alert = { message: 'Virhe tapahtui.', type: 'error', key: (new Date()).toString() }
                }
                state.messageIsLoading = false;
            })

            // summarizeDocuments
            .addCase(summarizeDocuments.pending, (state, action) => {
                const messages = state.selectedChat;
                messages.push({
                    id: null,
                    content: action.meta.arg.body.message,
                    role: 1,
                    timestamp: null,
                    sources: [],
                    orgMessage: "",
                    response_type: "default",
                    isLoading: true, 
                })
                messages.push({
                    id: null,
                    content: "Dokumentteja tiivistetään...",
                    role: 2,
                    timestamp: null,
                    sources: [],
                    orgMessage: "",
                    response_type: "default",
                    isLoading: true,
                })
                state.selectedChat = messages;
                state.messageIsLoading = true;
            })
            .addCase(summarizeDocuments.fulfilled, (state, action) => {
                state.selectedChatId = action.payload.conversation_id;
            })
            .addCase(summarizeDocuments.rejected, (state, action) => {
                console.log("error while summarizeDocuments:", action)
                if (action.error.message === "Request failed with status code 418") {
                    state.alert = { message: 'Virhe tapahtui. Tarkista tokenien määrä.', type: 'error', key: (new Date()).toString() }
                } else {
                    state.alert = { message: 'Virhe tapahtui.', type: 'error', key: (new Date()).toString() }
                }
                state.messageIsLoading = false;
            })

            //reviewDocuments
            .addCase(reviewDocuments.pending, (state, action) => {
                const messages = state.selectedChat;
                messages.push({
                    id: null,
                    content: action.meta.arg.body.message,
                    role: 1,
                    timestamp: null,
                    sources: [],
                    orgMessage: "",
                    response_type: "default",
                    isLoading: true
                })
                messages.push({
                    id: null,
                    content: "Dokumentteja tarkistetaan...",
                    role: 2,
                    timestamp: null,
                    sources: [],
                    orgMessage: "",
                    response_type: "default",
                    isLoading: true
                })
                state.selectedChat = messages;
                state.messageIsLoading = true;
            })
            .addCase(reviewDocuments.fulfilled, (state, action) => {
                state.selectedChatId = action.payload.conversation_id;
            })
            .addCase(reviewDocuments.rejected, (state, action) => {
                console.log("error while reviewDocuments:", action)
                if (action.error.message === "Request failed with status code 418") {
                    state.alert = { message: 'Virhe tapahtui. Tarkista tokenien määrä.', type: 'error', key: (new Date()).toString() }
                } else {
                    state.alert = { message: 'Virhe tapahtui.', type: 'error', key: (new Date()).toString() }
                }
                state.messageIsLoading = false;
            })

            //createChat
            .addCase(createChat.pending, (state, action) => {
                console.log("createChat pending action", action)
                const messages = [] as Message[]
                messages.push({
                    id: null,
                    content: action.meta.arg.body.message,
                    role: 1,
                    timestamp: null,
                    sources: [],
                    orgMessage: "",
                    response_type: "default",
                    isLoading: true
                })
                messages.push({
                    id: null,
                    content: "Vastausta ladataan...",
                    role: 2,
                    timestamp: null,
                    sources: [],
                    orgMessage: "",
                    response_type: "default",
                    isLoading: true
                })
                state.selectedChat = messages;
                state.messageIsLoading = true;
            })
            .addCase(createChat.fulfilled, (state, action) => {
                //state.selectedChat = action.payload.conversations;
                state.selectedChatId = action.payload.conversation_id;
            })
            .addCase(createChat.rejected, (state, action) => {
                console.log("error while createChat:", action)
                if (action.error.message === "Request failed with status code 418") {
                    state.alert = { message: 'Virhe tapahtui. Tarkista tokenien määrä.', type: 'error', key: (new Date()).toString() }
                } else {
                    state.alert = { message: 'Virhe tapahtui.', type: 'error', key: (new Date()).toString() }
                }
                state.messageIsLoading = false;
            })

            //rephrase
            .addCase(rephraseChat.pending, (state, action) => {
                const messages = state.selectedChat;
                //if (action.meta.arg.body.save_message) {
                messages.push({
                    id: null,
                    content: action.meta.arg.body.message,
                    role: 1,
                    timestamp: null,
                    sources: [],
                    orgMessage: "",
                    response_type: "rephrase",
                    isLoading: false,
                })
                //}
                messages.push({
                    id: null,
                    content: "Uudelleenmuotoilua ladataan...",
                    role: 2,
                    timestamp: null,
                    sources: [],
                    orgMessage: action.meta.arg.body.message,
                    response_type: "rephrase",
                    isLoading: true,
                })
                state.selectedChat = messages;
                state.messageIsLoading = true;
            })
            .addCase(rephraseChat.fulfilled, (state, action) => {
                const messages = state.selectedChat;
                if (action.payload.status != 201) {
                    const lastMessage = messages.pop();

                    if (lastMessage?.response_type === "rephrase") {
                        messages.push({ ...lastMessage, content: action.payload.data.response, isLoading: false })
                        state.selectedChat = messages;
                    }
                }

                if (action.payload.data.conversation_id)
                    state.selectedChatId = action.payload.data.conversation_id;

                state.messageIsLoading = false;
            })
            .addCase(rephraseChat.rejected, (state, action) => {
                state.messageIsLoading = false;
                state.alert = { message: 'Virhe tapahtui.', type: 'error', key: (new Date()).toString() }
                console.log("error while rephrase:", action)
            })

            //fetchChats
            .addCase(fetchChats.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(fetchChats.fulfilled, (state, action) => {
                state.isLoading = false;
                state.firstLoading = false;
                state.conversations = action.payload.conversations;
                state.query_quota = action.payload.query_quota;
            })
            .addCase(fetchChats.rejected, (state, action) => {
                state.isLoading = false;
                state.firstLoading = false;
                console.log("error while getting chats:", action)
            })

            //
            //deleteChat
            .addCase(deleteChat.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(deleteChat.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(deleteChat.rejected, (state, action) => {
                state.isLoading = false;
                console.log("error while deleteChat:", action)
            })

            //abortChat
            .addCase(abortChat.pending, (state, action) => {
                state.isLoading = true;
                const messages = state.selectedChat;
                //idiotic way to remove two last chat messages
                messages.pop();
                messages.pop();
                state.selectedChat = messages;
                state.messageIsLoading = false;
            })
            .addCase(abortChat.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(abortChat.rejected, (state, action) => {
                state.isLoading = false;
                console.log("error while abortChat:", action)
            })

            //fetchChat
            .addCase(fetchChat.pending, (state, action) => {
                state.messageIsLoading = true;
            })
            .addCase(fetchChat.fulfilled, (state, action) => {
                state.messageIsLoading = false;
                const messages = [] as Message[]
                action.payload.conversations.map((m: ServerMessage) => {
                    messages.push({
                        id: m.id,
                        content: m.message,
                        role: 1,
                        timestamp: m.timestamp,
                        sources: [],
                        orgMessage: m.message,
                        response_type: m.response_type,
                        isLoading: false,
                    })
                    if (m.response !== "")
                        messages.push({
                            id: m.id,
                            content: m.response,
                            role: 2,
                            timestamp: m.timestamp,
                            sources: m.sources,
                            orgMessage: m.message,
                            response_type: m.response_type,
                            isLoading: false,
                            warning: m.warning,
                            role_id: m.role_id,
                        })
                });
                state.selectedChat = messages;
            })
            .addCase(fetchChat.rejected, (state, action) => {
                state.messageIsLoading = false;
                console.log("error while getting chat:", action)
            })
    }

})

export const { setSelectedChatId, resetAlert, setAlert, setSelectedPipe } = chatSlice.actions;

export default chatSlice.reducer;
export const chatSelector = (state: RootState) => state.chat;
export const selectedChatSelector = (state: RootState) => state.chat.selectedChat;
